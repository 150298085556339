import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { StateService } from "./services/state.service";
import { NotificationService } from "./services/notification/notification.service";
import { decodeToken, getFormatedCountByStatus } from "./util/common.util";
import { Filter, FilterService } from "./services/filter.service";
import { INotification } from "./models/notification.model";
import { MessageService } from "primeng/api";
import { NotificationDetailComponent } from "./notification/components/notification-detail/notification-detail.component";
import { Observable, of, Subscription } from "rxjs";
import { ActivityService } from "./services/activity.service";
import {
  CHECK_NOTIFICATION_LIST_UPDATED_CALL_INTERVAL,
  USER_ACTIVITY_MESSAGE_INTERVAL,
} from "./constants/common.constants";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

interface IPanel {
  label: string;
  value: string;
}
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent {
  private inactivitySubscription: Subscription = new Subscription();
  private activitySubscription: Subscription = new Subscription();

  isNotificationListUpdated: boolean = false;
  checkNotificationUpdatedInterval: any = null;

  constructor(
    private readonly state: StateService,
    private readonly notificationService: NotificationService,
    private readonly filterService: FilterService,
    private readonly cdr: ChangeDetectorRef,
    private readonly messageService: MessageService,
    private readonly activityService: ActivityService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @ViewChild(NotificationDetailComponent)
  notificaitonDetailComponent!: NotificationDetailComponent;

  title(title: any) {
    throw new Error("Method not implemented.");
  }
  headerData: any;
  first: number = 0;
  rows: unknown;
  products: any[""];
  covidtest: any[""];
  sidebarVisible2: any;
  selectedTab: string = "all";
  sortBy: string = "";
  currentSort: string = "";
  panelSelectionPlaceholder: string = "Please select the panel";
  hasComments: boolean = false;
  hasUnread: boolean = false;
  appliedFilters: Filter[] = [];
  allEmailSubscribed: boolean = false;
  customEmailSubscribed: boolean = false;
  isLoadingSettings: boolean = false;
  isSavingSettings: boolean = false;
  isLoadingNotifications: boolean = false;
  search: string = "";

  // Custome Pagination attributes
  totalItems = 120;
  rowsPerPage = 10;
  currentPage = 1;
  //
  notificationCountByStatus: any = {};
  panels!: IPanel[];
  notifications: any[] = [];
  selectedNotification: any;
  totalNotifications: number = 0;
  notificationsinList: number = 0;
  appliedFilterCount: number = 0;
  customEmailOptions: any[] = [];
  userActivityInterval: any;
  lastActivityTime: any = new Date();
  isMobile$: Observable<any> = of(null);

  public selectedPanels: any[] = [];

  // Handle the pagination change event
  onPageChange(event: any) {
    this.currentPage = event.currentPage;
    this.rowsPerPage = event.rowsPerPage;
    this.getNotificationsCall();
  }
  onPanelChange(event: any) {
    if (event.value.length > 1) {
      this.panelSelectionPlaceholder = "Multiple Panels Selected";
    }
    this.currentPage = 1;
    this.getNotificationsCall();
  }
  ngOnInit() {
    window.addEventListener("message", (event) => this.handleMessage(event));
    this.activitySubscription = this.activityService
      .onActivity()
      .subscribe(() => {
        this.lastActivityTime = new Date();
      });

    this.inactivitySubscription = this.activityService
      .monitorInactivity(USER_ACTIVITY_MESSAGE_INTERVAL)
      .subscribe();

    this.checkNotificationUpdatedInterval = setInterval(() => {
      this.notificationService.getNotificationHasUpdates().subscribe({
        next: (res) => {
          if (!res.success) {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail:
                res.message || "Error while checking for notification updates",
            });
            return;
          }
          this.isNotificationListUpdated = res.hasUpdates;
        },
        error: (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail:
              err.message || "Error while checking for notification updates",
          });
        },
      });
    }, CHECK_NOTIFICATION_LIST_UPDATED_CALL_INTERVAL);
    this.isMobile$ = this.breakpointObserver.observe([Breakpoints.Handset]);
  }

  ngOnDestroy(): void {
    clearInterval(this.userActivityInterval);
    this.userActivityInterval = null;
    window.removeEventListener("message", () => console.log("cleanup"));
    if (this.inactivitySubscription) {
      this.inactivitySubscription.unsubscribe();
    }
    if (this.activitySubscription) {
      this.activitySubscription.unsubscribe();
    }
    this.activityService.stopMonitoring();
    clearInterval(this.checkNotificationUpdatedInterval);
    this.checkNotificationUpdatedInterval = null;
  }

  getEmailSettingsCall() {
    this.isLoadingSettings = true;
    this.notificationService.getUserSettings().subscribe({
      next: (res) => {
        this.allEmailSubscribed = res.emails_enabled;
        this.customEmailSubscribed = res.is_customized;
        this.customEmailOptions = this.state
          .getSavedFilters()
          ?.map((option) => {
            if (
              res.setting_filters?.find(
                (setting: { filter: { id: any } }) =>
                  setting.filter?.id === option.id
              )
            ) {
              return { label: option.name, value: option.id, checked: true };
            }
            return { label: option.name, value: option.id, checked: false };
          });
        this.isLoadingSettings = false;
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.message || "Error while fetching email settings",
        });
        this.isLoadingSettings = false;
      },
    });
  }
  onSearchChange() {
    this.currentPage = 1;
    this.getNotificationsCall();
  }
  onChangeEmailSettings(event: any) {
    if (event.target.id === "allEmailSubscribed") {
      this.allEmailSubscribed = event.target.checked;
      this.customEmailSubscribed = false;
    } else {
      this.allEmailSubscribed = false;
      this.customEmailSubscribed = event.target.checked;
    }
    this.customEmailOptions = this.customEmailOptions.map((option) => ({
      ...option,
      checked: false,
    }));
  }
  onChangeCustomEmailSettings(event: any) {
    this.customEmailOptions = this.state.getSavedFilters().map((filter) => {
      const foundEvent = event.find((e: any) => e.value === filter.id);
      if (foundEvent) {
        return {
          label: filter.name,
          value: filter.id,
          checked: foundEvent.checked,
        };
      }
      return { label: filter.name, value: filter.id, checked: false };
    });
  }
  onSaveEmailSettings() {
    const body = {
      emails_enabled: this.allEmailSubscribed || false,
      is_customized: this.customEmailSubscribed || false,
      filter_ids: this.customEmailOptions
        ?.map((option) => {
          if (option.checked) {
            return option.value;
          }
        })
        .filter((option) => option),
    };
    if (body.is_customized && body.filter_ids.length === 0) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please select atleast one filter",
      });
      return;
    }
    this.isSavingSettings = true;
    this.notificationService.updateUserSettings(body).subscribe({
      next: (res) => {
        this.getEmailSettingsCall();
        this.sidebarVisible2 = false;
        this.isSavingSettings = false;
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Email settings updated",
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.message || "Error while updating email settings",
        });
        this.isLoadingSettings = false;
      },
    });
  }
  onCloseEmailSettings() {
    this.customEmailOptions = this.state.getSavedFilters().map((filter) => ({
      label: filter.name,
      value: filter.id,
    }));
    this.getEmailSettingsCall();
    this.sidebarVisible2 = false;
  }
  onTabChangeHandler(selectedTab: any) {
    this.currentPage = 1;
    this.selectedTab = selectedTab;
    this.getNotificationsCall();
  }
  onSortHandler(sort: string) {
    this.currentPage = 1;
    if (this.currentSort === sort) {
      this.sortBy = "";
      this.hasComments = false;
      this.hasUnread = false;
      this.currentSort = "";
      this.getNotificationsCall();
      return;
    }
    this.currentSort = sort;

    switch (sort) {
      case "Newest":
      case "Oldest":
        this.sortBy = sort;
        this.hasComments = false;
        this.hasUnread = false;
        break;
      case "comments":
        this.sortBy = "";
        this.hasComments = true;
        this.hasUnread = false;
        break;
      case "unread":
        this.sortBy = "";
        this.hasComments = false;
        this.hasUnread = true;
        break;
      default:
        this.sortBy = "";
        this.hasComments = false;
        this.hasUnread = false;
        break;
    }
    this.getNotificationsCall();
  }
  getNotificationPayload() {
    const body = {
      pageSize: this.rowsPerPage,
      pageNo: this.currentPage,
      status: this.selectedTab,
    } as any;
    if (this.sortBy) {
      body.sort = this.sortBy === "Newest" ? "DESC" : "ASC";
    } else if (this.hasComments) {
      body.onlyWithComments = this.hasComments;
    } else if (this.hasUnread) {
      body.onlyUnRead = this.hasUnread;
    }
    if (this.appliedFilters?.length > 0) {
      body.filters = this.appliedFilters;
    }
    if (this.selectedPanels?.length > 0) {
      body.panels = this.selectedPanels.map((panel) =>
        panel.replace("panel-", "")
      );
    }
    if (this.search) {
      body.search = this.search;
    }
    return body;
  }
  getNotificationsCall() {
    this.isLoadingNotifications = true;
    this.notificationService
      .getNotifications(this.getNotificationPayload())
      .subscribe({
        next: (notifications) => {
          this.state.setNotifications(notifications.data);
          this.state.setNotificationCountByStatus(
            getFormatedCountByStatus(notifications.metaData)
          );
          this.notificationCountByStatus =
            this.state.getNotificationCountByStatus();
          this.totalNotifications =
            notifications?.metaData?.totalFilteredCount || 0;
          this.notificationsinList = notifications?.data?.length || 0;
          this.notifications = notifications.data;
          this.isLoadingNotifications = false;
          this.isNotificationListUpdated = false;
        },
        error: (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: err.message || "Error while fetching notifications",
          });
          this.isLoadingNotifications = false;
        },
      });
  }

  setSelectedNotification(index: any) {
    this.selectedNotification = this.notifications[index];
  }

  filtersVisible = false;
  openFilters() {
    this.filtersVisible = true;
  }
  closeFilters() {
    this.filtersVisible = false;
  }
  openSettingsDrawer() {
    this.isLoadingSettings = true;
    this.filterService.getAllSavedFilters().subscribe({
      next: (data) => {
        this.state.setSavedFilters(data);
        this.customEmailOptions = data.map((filter) => ({
          label: filter.name,
          value: filter.id,
        }));
        this.getEmailSettingsCall();
      },
      error: (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.message || "Error while fetching filters",
        });
        console.error("Error while subscribing:");
      },
    });
    this.sidebarVisible2 = true;
  }
  handleAppliedFilters(appliedFilterCount: number) {
    this.appliedFilterCount = appliedFilterCount;
  }

  onNotificationSelect(notification: INotification) {
    this.selectedNotification = notification;
  }

  setIntervalForUserActivity() {
    this.userActivityInterval = setInterval(() => {
      if (
        this.lastActivityTime &&
        new Date().getTime() - this.lastActivityTime.getTime() <=
          USER_ACTIVITY_MESSAGE_INTERVAL
      ) {
        window.parent.postMessage({ type: "UUX-Activity" }, "*");
      }
    }, USER_ACTIVITY_MESSAGE_INTERVAL);
  }

  handleInitialToken(data: any) {
    const token = data["accessToken"];
    this.state.setUser({
      ...this.state.getUser(),
      email: decodeToken(token)?.username,
    });
    const { "cognito:groups": panels, iss } = decodeToken(token);
    this.panels =
      panels
        ?.filter((group: string | string[]) => group.includes("panel-"))
        .map((group: string) => ({
          label: group.replace("panel-", "").replaceAll("_", " "),
          value: group.replace("panel-", ""),
        })) || [];
    const parts = iss.split("/");
    const region =
      parts[2].split(".")[1] && parts[2].split(".")[1] !== "null"
        ? parts[2].split(".")[1]
        : "southeast-1";
    const userPoolId =
      parts[3] && parts[3] !== "null" ? parts[3] : "ap-southeast-1_ezYBJeap6";
    const organizationName =
      data["organizationName"] && data["organizationName"] !== "null"
        ? data["organizationName"]
        : "mx-dev-v3";
    const uuxSessionId =
      data["uuxSessionId"] && data["uuxSessionId"] !== "null"
        ? data["uuxSessionId"]
        : 1234;
    if (token) {
      this.state.setAccessToken(token);
      this.state.setMXRequestHeaderFields({
        region,
        "uux-session-id": uuxSessionId,
        organization: organizationName,
        pool_id: userPoolId,
      });
      this.headerData = {
        token: true,
        region,
        "uux-session-id": uuxSessionId,
        organization: organizationName,
        pool_id: userPoolId,
      };
      const body = {
        pageSize: 10,
        pageNo: 1,
        status: "all",
      } as any;
      this.isLoadingNotifications = true;
      this.notificationService.getNotifications(body).subscribe({
        next: (notifications) => {
          if (notifications?.data?.length > 0) {
            this.state.setNotifications(notifications.data);
            this.state.setNotificationCountByStatus(
              getFormatedCountByStatus(notifications.metaData)
            );
            this.notificationCountByStatus =
              this.state.getNotificationCountByStatus();
            this.totalNotifications =
              notifications?.metaData?.totalFilteredCount || 0;
            this.notificationsinList = notifications?.data?.length || 0;
            this.notifications = notifications.data;
            this.isLoadingNotifications = false;
          }
        },
        error: (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: err.message || "Error while fetching notifications",
          });
          this.isLoadingNotifications = false;
        },
      });

      this.filterService.getAllSavedFilters().subscribe({
        next: (data) => {
          this.state.setSavedFilters(data); // Assigns the fetched filters to a component variable
          this.customEmailOptions = data.map((filter) => ({
            label: filter.name,
            value: filter.id,
          }));
          this.getEmailSettingsCall();
        },
        error: (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: err.message || "Error while fetching filters",
          });
        },
      });

      this.setIntervalForUserActivity();
    }
  }

  handleTokenRefresh(data: any) {
    const token = data["accessToken"];
    this.state.setUser({
      ...this.state.getUser(),
      email: decodeToken(token)?.username,
    });
    const { "cognito:groups": panels, iss } = decodeToken(token);
    this.panels =
      panels
        ?.filter((group: string | string[]) => group.includes("panel-"))
        .map((group: string) => ({
          label: group.replace("panel-", "").replaceAll("_", " "),
          value: group.replace("panel-", ""),
        })) || [];
    const parts = iss.split("/");
    const region =
      parts[2].split(".")[1] && parts[2].split(".")[1] !== "null"
        ? parts[2].split(".")[1]
        : "southeast-1";
    const userPoolId =
      parts[3] && parts[3] !== "null" ? parts[3] : "ap-southeast-1_ezYBJeap6";
    if (token) {
      this.state.setAccessToken(token);
      this.state.setMXRequestHeaderFields({
        region,
        pool_id: userPoolId,
      });
      this.headerData = {
        ...this.headerData,
        token: true,
        region,
        pool_id: userPoolId,
      };
      this.lastActivityTime = new Date();
    }
  }

  handleLogout() {
    this.notificationService.logout().subscribe({
      next: () => {},
      error: (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.message || "Error while logging out",
        });
      },
    });
    this.state.logout();
    this.headerData = null;
    clearInterval(this.userActivityInterval);
    this.userActivityInterval = null;
  }

  handleMessage(event: MessageEvent) {
    // if (
    //   event.origin.includes("http://localhost:4200") ||
    //   event.origin.includes("https://mx-dev-wrapper.stella-apps.com")
    // ) {
    if (event?.data?.type) {
      switch (event.data.type) {
        case "UUX-Login":
          this.handleInitialToken(event.data);
          break;
        case "UUX-Refresh":
          this.handleTokenRefresh(event.data);
          break;
        case "UUX-Logout":
          this.handleLogout();
          break;

        default:
          break;
      }
    }
    // }
  }

  selectedCategories: any[] = [];

  categories: any[] = [
    { name: "Accounting", key: "A" },
    { name: "Marketing", key: "M" },
    { name: "Production", key: "P" },
    { name: "Research", key: "R" },
  ];
  notificationDetail: any = {};

  handleApplyFilters(filters: Filter[]) {
    this.currentPage = 1;
    this.appliedFilters = filters;
    this.appliedFilterCount = filters.length;
    this.getNotificationsCall();
  }
  scrollToComments() {
    this.notificaitonDetailComponent.scrollToComments();
  }
  handleStatusChange(event: {
    notificationId: string;
    status: string;
    previousStatus: string;
  }) {
    if (this.notifications) {
      this.notifications = [
        ...this.notifications.map((notification) => {
          if (notification.id === event.notificationId) {
            return { ...notification, status: event.status };
          } else {
            return { ...notification };
          }
        }),
      ];
    }
    this.notificationCountByStatus[event.status] =
      this.notificationCountByStatus[event.status] + 1;
    this.notificationCountByStatus[event.previousStatus] =
      this.notificationCountByStatus[event.previousStatus] - 1;
  }

  handleDownloadNotification(notificationType: string) {
    let body = this.getNotificationPayload();
    if (notificationType) {
      body.notificationType = notificationType;
    }
    delete body.pageNo;
    delete body.pageSize;

    body = {
      ...body,
      sort: body.sort ?? "DESC",
      panels: body.panels ?? this.panels.map((panel) => panel.value),
      onlyUnRead: body.onlyUnRead || false,
      onlyWithComments: body.onlyWithComments || false,
      filters: body.filters ?? [],
    };
    this.messageService.add({
      severity: "info",
      summary: "Downloading",
      detail: `Downloading ${notificationType || ""} notifications`,
    });
    this.notificationService.downloadNotification(body).subscribe({
      next: (res) => {
        const blob = new Blob([res], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${notificationType}-Notifications.csv`;
        anchor.click();
        window.URL.revokeObjectURL(url);
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Notifications downloaded successfully",
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.message || "Error while downloading notifications",
        });
      },
    });
  }

  handleListRefresh() {
    this.getNotificationsCall();
  }
  updateOpenedNotification(event: any) {
    this.notificationCountByStatus[event.status] =
      this.notificationCountByStatus[event.status] + 1;
    this.notificationCountByStatus[event.previousStatus] =
      this.notificationCountByStatus[event.previousStatus] - 1;
    if (
      this.selectedNotification &&
      this.selectedNotification.id === event.id
    ) {
      this.selectedNotification = {
        ...this.selectedNotification,
        status: event.status,
      };
    }
  }
  onClearSearch() {
    this.search = "";
    this.currentPage = 1;
    this.getNotificationsCall();
  }
}
